/* @import "./assets/circle.css"; */
@import "./assets/circle1.css";
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.8;
  color: #212123;
}
a {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.fontWeightSemibold {
  font-weight: 600 !important;
}
.fontWeightMedium {
  font-weight: 500 !important;
}
.img-fluid {
  max-width: 100%;
  display: block;
  height: auto;
}
/* Header */

header .MuiSelect-selectMenu {
  padding: 22px 23px 23px 16px;
  color: rgba(3, 27, 78, 0.9);
  font-weight: normal;
}
header.sticky {
  background: #fff !important;
}
.langmenu .MuiPopover-paper {
  border: 1px solid #ebebeb !important;
  background: #fff !important;
}
.langmenu .MuiMenuItem-root {
  color: rgba(3, 27, 78, 0.9);
  font-size: 16px;
  font-weight: normal;
  background: #fff;
}
header .MuiInput-underline:after,
header .MuiInput-underline:before {
  content: none;
}
header .MuiSelect-select:focus {
  background-color: transparent;
}
.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.MuiPopover-paper {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 1px solid #d0d7dd !important;
  border-top: 0 !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: transparent !important;
}
.MuiListItem-button:hover {
  background-color: transparent !important;
  color: #0112c2;
}
.MuiSelect-icon {
  right: 7px !important;
}
.main {
  padding-top: 64px;
}
* {
  box-sizing: border-box;
}
.firstFramework:before,
.firstFramework:after {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  transition: all 0.4s linear;
  left: -30px;
  top: -30px;
  background: #111111;
}
.firstFramework:after {
  right: 0;
  bottom: 0;
  left: auto;
  top: auto;
}
.frameworkRow .MuiGrid-item:last-child .firstFramework:before,
.frameworkRow .MuiGrid-item:last-child .firstInnerbox:before {
  left: 0;
}
.frameworkRow .MuiGrid-item:last-child .firstFramework:after,
.frameworkRow .MuiGrid-item:last-child .firstInnerbox:after {
  right: -30px;
}
.frameworkRow .MuiGrid-item:first-child .firstFramework:before,
.frameworkRow .MuiGrid-item:last-child .firstFramework:after {
  width: 25px;
}
.firstInnerbox:before,
.firstInnerbox:after {
  content: "";
  width: 1px;
  height: 0;
  position: absolute;
  transition: all 0.4s linear;
  left: -30px;
  top: -30px;
  background: #111111;
}
.firstInnerbox:after {
  right: 0;
  bottom: 0;
  left: auto;
  top: auto;
}
.frameworkRow .MuiGrid-item:last-child .firstInnerbox:after,
.frameworkRow .MuiGrid-item:first-child .firstInnerbox:before {
  height: 25px;
}
.frameworkRow .firstFramework:hover:after,
.frameworkRow .firstFramework:hover:before {
  width: 107.4% !important;
}
.frameworkRow .firstFramework:hover .firstInnerbox:after,
.frameworkRow .firstFramework:hover .firstInnerbox:before {
  height: 105.8% !important;
}
.frameworkRow {
  margin-top: 5rem;
}
.read_more {
  color: #0112c2;
  font-weight: 500;
  position: relative;
  z-index: 9;
  padding-left: 10px;
}
.read_more span {
  margin-left: -10px;
  transition: all 0.3s linear;
}
.read_more:after {
  left: 0;
  width: 100%;
  bottom: 0;
  height: 1px;
  content: "";
  z-index: -1;
  position: absolute;
  background: #0112c2;
  transition: all 0.3s linear;
}
.read_more:hover {
  padding-right: 10px;
}
.read_more:hover span {
  color: #fff;
  z-index: 9;
  margin-left: 0;
}
.read_more:hover:after {
  height: 100%;
}
.read_more_inverse {
  color: #ffffff;
  position: relative;
  z-index: 9;
  padding-left: 10px;
}
.read_more_inverse span {
  margin-left: -10px;
}
.read_more_inverse:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ffffff;
  left: 0;
  bottom: 0;
  transition: all 0.3s linear;
  z-index: -1;
}
.read_more_inverse:hover span {
  color: #0112c2;
  z-index: 9;
  margin: 0;
  transition: all 0.3s linear;
}
.read_more_inverse:hover:after {
  height: 100%;
}
.read_more_inverse:hover {
  padding-right: 10px;
}
.imgResponsive {
  max-width: 100%;
}
.btn.MuiButton-root {
  min-width: 165px;
  height: 50px;
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
  border-radius: 0;
  border-radius: 5px;
  border: 1px solid;
}
.MuiButton-containedPrimary {
  color: #0112c2 !important;
  background: #e5ecfd !important;
  border-color: #e5ecfd !important;
}
.MuiButton-containedPrimary:hover {
  background-color: rgba(1, 18, 194, 0.08) !important;
  color: #0112c2 !important;
}
.btnBlack.btn.MuiButton-root {
  background: #212123;
  color: #ffffff;
}
.btnBlack.btn.MuiButton-root:hover {
  background-color: rgba(33, 33, 35, 0.08) !important;
  color: #212123;
}
.btnWhite.MuiButton-root {
  background: transparent;
  color: #ffffff;
  border-color: #ffffff;
}
.btnBlackoutline.btn.MuiButton-root {
  border-color: #212123;
  color: #212123;
}
.btnOrangeoutline.btn.MuiButton-root {
  border-color: #ff6d4a;
  color: #ff6d4a;
}
.btnOrangeoutline.btn.MuiButton-root:hover {
  background-color: rgba(255, 109, 74, 0.08);
}
.listInline,
.listUnstyled {
  padding: 0;
}
.listInline li {
  display: inline-block;
}
.listUnstyled {
  list-style: none;
}
.pl10 {
  padding-left: 10%;
}
.MuiBadge-badge {
  top: -5px !important;
  right: -32px !important;
  height: 14px !important;
  padding: 0 4px !important;
  font-size: 9px !important;
  min-width: 65px !important;
  border-radius: 3px !important;
  flex-direction: row !important;
  line-height: 2px !important;
}
.progress {
  width: 80px;
  height: 80px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
}
.dotCircle .progress {
  top: -7px;
  left: -7px;
}
.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border: 2px solid #0112c2;
  position: absolute;
  top: 0;
}
.dotCircle .progress .progress-bar {
  border: 2px solid #0112c2;
}
.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.frameworkRow .MuiTabs-indicator {
  margin: 0 !important;
  position: absolute !important;
  transition: 1s linear !important;
  width: 0 !important;
  height: 0 !important;
  top: 40px !important;
  border-radius: 50% !important;
}
.frameworkRow .MuiTabs-indicator:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: #0112c2;
  border-radius: 50%;
  right: -162px;
}
.frameworkRow span.icons {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #d9e2eb;
  position: relative;
  transition: all 0.3s linear;
  line-height: 100px;
  margin-bottom: 0 !important;
  background: #fff;
  z-index: 9;
  color: rgba(3, 27, 78, 0.7);
}
.frameworkRow .Mui-selected span.icons {
  background: #0112c2;
  transition-delay: 2.6s;
  color: #fff;
  border: 1px solid #0112c2;
}
.frameworkRow .Mui-selected span.icons .MuiSvgIcon-root {
  z-index: 9;
  position: relative;
}
.frameworkRow .Mui-selected span.icons:after {
  content: "";
  position: absolute;
  background: #0112c2;
  border-radius: 50%;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0 auto;
}
.frameworkRow .Mui-selected span.icons:after {
  animation: grow 0.5s linear forwards;
  animation-delay: 2.2s;
}
@keyframes grow {
  0% {
    width: 20px;
    height: 20px;
    opacity: 0;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}
.frameworkRow span.label {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 500;
  text-transform: initial;
  bottom: -65px;
  height: 48px;
  color: #031b4e;
  opacity: 0.7;
}
.frameworkRow .MuiTab-wrapper {
  position: relative;
  margin-top: -65px;
}
.frameworkRow .MuiTab-wrapped {
  overflow: visible;
  min-height: 160px;
}
.Mui-selected .progress .progress-right .progress-bar {
  animation: loading-1 0.6s linear forwards;
  animation-delay: 1s;
}
.Mui-selected .progress .progress-left .progress-bar {
  animation: loading-2 0.6s linear forwards 0.6s;
  animation-delay: 1.6s;
}
.active .progress .progress-right .progress-bar {
  animation: loading-1 3s linear forwards;
  animation-delay: 3s;
}
.active .progress .progress-left .progress-bar {
  animation: loading-2 3s linear forwards 0.6s;
  animation-delay: 6s;
}
.nextButton:hover .progress .progress-right .progress-bar,
.previousButton:hover .progress .progress-right .progress-bar {
  animation: loading-1 0.6s linear forwards;
}
.nextButton:hover .progress .progress-left .progress-bar,
.previousButton:hover .progress .progress-left .progress-bar {
  animation: next 0.6s linear forwards 0.6s;
  animation-delay: 0.6s;
}
.nextButton .progress {
  transform: rotate(-52deg);
}
.previousButton .progress {
  transform: rotate(-231deg);
}
.previousButton .progress,
.nextButton .progress {
  width: 100%;
  height: 100%;
}
.previousButton .progress .progress-bar,
.nextButton .progress .progress-bar {
  border-width: 3px;
}
.frameworkRow .MuiTab-wrapped:not(:last-child) span.MuiTab-wrapper:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #d9e2eb;
  position: absolute;
  left: 50%;
  top: 42px;
  z-index: -1;
}
.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #44484b;
  font-size: 24px;
  color: #fff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}
.frameworkRow .progress .progress-bar {
  border-color: var(--v-primary-base);
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes next {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(103deg);
    transform: rotate(103deg);
  }
}
.testimonial_slides {
  margin-bottom: 40px;
}
.testimonial_slides .MuiList-root {
  display: flex;
  justify-content: space-around;
  max-width: 260px;
  margin: 50px auto;
}
.testimonial_slides .MuiListItem-root {
  display: inline-flex;
  width: auto;
  padding: 0;
}

.testimonial_slides .MuiTypography-colorTextSecondary {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
}
.testimonial_slides .MuiListItemAvatar-root {
  min-width: 86px;
}
.testimonial_slides .MuiAvatar-root {
  width: 60px;
  height: 60px;
}
.react-swipeable-view-container {
  transition: transform 1s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
}
/* menu */
.MuiDrawer-modal.rightDrawer .MuiDrawer-paper {
  width: 100%;
  background: #0112c2;
  background: #212123;
}
.MuiDrawer-modal.leftDrawer .MuiDrawer-paper {
  width: 80%;
  background: #f7fafc;
}
.MuiDrawer-root {
  left: auto !important;
  right: auto !important;
}
header label .menu {
  position: absolute;
  right: 13px;
  top: 10px;
  z-index: 100;
  width: 45px;
  height: 45px;
  background: #0112c2;
  border-radius: 50%;
  transition: 1s cubic-bezier(0.59, 0.28, 0.39, 0.64);
  box-shadow: 0 0 0 0 #0112c2, 0 0 0 0 #0112c2;
  cursor: pointer;
}
header label .overlay {
  position: absolute;
  right: 36px;
  top: 33px;
  z-index: 100;
  width: 45px;
  height: 45px;
  background: #0112c2;
  border-radius: 50%;
  transition: 1s ease-in-out;
  transform-origin: center center;
  transform: translate3d(50%, -50%, 0);
  border: 1px solid #0112c2;
}
header label .hamburger {
  position: absolute;
  top: 21px;
  left: 11px;
  width: 24px;
  height: 2px;
  background: #fff;
  display: block;
  transform-origin: center;
  transition: 0.5s ease-in-out;
}
header label .hamburger:after,
header label .hamburger:before {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
}
header label .hamburger:before {
  top: -7px;
}
header label .hamburger:after {
  bottom: -7px;
  width: 18px;
  right: 0;
}
header label input {
  display: none;
}
/* label input:checked + .menu {
  box-shadow: 0 0 0 105vw #0112c2, 0 0 0 100vh #0112c2;
} */
header label input:checked + .overlay {
  transform: translate3d(50%, -50%, 0);
  width: 360vw;
  height: 360vw;
  transform-origin: center center;
}
header label input:checked + .overlay + .menu .hamburger {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
header label input:checked + .overlay + .menu .hamburger:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  bottom: 0;
  right: 3px;
}
header label input:checked + .overlay + .menu .hamburger:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
}
header label input:checked + .overlay + .menu + ul {
  opacity: 1;
}
header label ul {
  z-index: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 1s 0.05s cubic-bezier(1, -0.01, 0, 1.02);
}
.comeup {
  animation: comeup 0.5s linear forwards;
}
@keyframes comeup {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
/* modal */
.MuiDialogContent-root {
  padding: 80px 65px 15px !important;
}
.btnMadalClose {
  position: absolute;
  top: 25px;
  right: 25px;
}
.MuiPaper-rounded.MuiDialog-paper {
  border-radius: 12px;
  box-shadow: 0 29px 60px 0 rgba(54, 57, 73, 0.09);
}
.App {
  transition: all 0.3s;
  overflow-x: hidden;
}
/* #root[aria-hidden="true"] .App {
  transform: perspective(6500px) rotateX(-2deg);
} */

/* send */
.checkmark {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 1s linear 0.8s forwards;
  animation-delay: 6s;
  stroke-width: 3px;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
.position-relative {
  position: relative;
}
.loader {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url("#goo");
}
.progress.go .loader {
  animation: rotate-move 2s ease-in-out 3;
}
.loader .dot {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.progress.go .loader .dot-3 {
  background-color: #0112c2;
  animation: dot-3-move 2s ease 3, index 6s ease 3;
}
.progress.go .loader .dot-2 {
  background-color: #0112c2;
  animation: dot-2-move 2s ease 3, index 6s -4s ease 3;
}
.progress.go .loader .dot-1 {
  background-color: #0112c2;
  animation: dot-1-move 2s ease 3, index 6s -2s ease 3;
}
@keyframes dot-3-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translateY(-18px) scale(0.45);
  }
  60% {
    transform: translateY(-90px) scale(0.45);
  }
  80% {
    transform: translateY(-90px) scale(0.45);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}
@keyframes dot-2-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translate(-16px, 12px) scale(0.45);
  }
  60% {
    transform: translate(-80px, 60px) scale(0.45);
  }
  80% {
    transform: translate(-80px, 60px) scale(0.45);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}
@keyframes dot-1-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translate(16px, 12px) scale(0.45);
  }
  60% {
    transform: translate(80px, 60px) scale(0.45);
  }
  80% {
    transform: translate(80px, 60px) scale(0.45);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}
@keyframes rotate-move {
  55% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  80% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes index {
  0%,
  100% {
    z-index: 3;
  }
  33.3% {
    z-index: 2;
  }
  66.6% {
    z-index: 1;
  }
}
/* send */

/* contact us */
.MuiBreadcrumbs-li a {
  color: #b5bec8;
  font-weight: normal;
  font-size: 14px;
}
.MuiBreadcrumbs-li .MuiTypography-body1 {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  line-height: 1.5;
}
.MuiBreadcrumbs-separator {
  font-size: 10px;
  color: #b5bec8;
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.location .MuiTypography-body2 {
  color: #79787d;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
}
.location .MuiTypography-body1 {
  font-size: 22px;
  font-weight: 500;
  line-height: 2.5;
}
.location .MuiAvatar-colorDefault {
  color: #fafafa;
  background-color: transparent;
  border-radius: 0;
  width: auto;
  height: auto;
  margin-right: 25px;
  justify-content: flex-start;
}
.location .MuiListItem-gutters {
  padding: 8px 0;
}
.text-right {
  text-align: right;
}
.float-right {
  float: right;
}
.contactForm .MuiInput-underline:after {
  content: none;
}
.contactForm .MuiInput-underline:before {
  border-bottom: 0 !important;
  background: #e9eff4;
  z-index: -1;
  right: 24px;
  height: 15px;
  bottom: 4px;
}
.contactForm .MuiSelect-selectMenu:focus {
  background: transparent;
}
.contactForm .MuiSelect-icon {
  right: -10px !important;
  color: #0112c2 !important;
  font-size: 2rem;
  top: calc(50% - 15px);
}
.inquiryForm .MuiMenu-paper {
  max-height: calc(100% - 96px);
  -webkit-overflow-scrolling: touch;
  padding: 15px;
  border: 1px solid #dce0f6 !important;
  border-radius: 8px !important;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1) !important;
}
.contactForm .MuiSelect-selectMenu {
  font-size: 22px;
}
.inquiryForm .MuiMenuItem-root {
  color: #212123;
  opacity: 1;
}
.MuiFormControl-root.textField {
  display: block;
  height: 60px;
}
.MuiFormControl-root .MuiFilledInput-multiline {
  padding: 27px 12px 2px;
}
.MuiFormControl-root.textField.expand {
  height: auto;
}
.MuiFormControl-root.textField .MuiInputLabel-formControl {
  top: 3px;
  color: #a4abc5;
  font-weight: normal;
  font-size: 14px;
  left: 18px;
}
.MuiFormControl-root.textField .MuiInputLabel-formControl.Mui-focused {
  color: #0112c2;
}
.MuiFormControl-root.textField .MuiInputLabel-filled.MuiInputLabel-shrink {
  color: #0112c2;
}
.MuiFormControl-root.textField .MuiInputBase-root {
  display: block;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dce0f6;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}
.MuiFormControl-root.textField .MuiInputBase-root.Mui-focused {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-color: #0112c2;
}
.MuiFormControl-root.textField .MuiInputBase-root:before,
.MuiFormControl-root.textField .MuiInputBase-root:after {
  content: none;
}
.MuiFormControl-root.textField .MuiInputBase-root input {
  box-sizing: border-box;
  height: 100%;
  font-size: 16px;
  padding: 27px 30px 10px;
}
.MuiFormControl-root.textField .MuiInputBase-root textarea {
  box-sizing: border-box;
  font-size: 16px;
  padding: 0 17px 10px;
}
.MuiFormControl-root.textarea {
  height: 150px;
}
.swipeText {
  text-align: center;
  font-weight: 500;
  background: url("assets/img/b3.png") no-repeat;
  background-size: contain;
  background-position: center;
  color: #fff;
  margin-top: 15px;
  padding: 5px 0;
}
.box2 {
  margin-top: 90px !important;
}
.box3 {
  margin-top: -90px !important;
}
.box5 {
  margin-top: 40% !important;
}
.box6 {
  margin-top: 28% !important;
}
.w100 {
  width: 100%;
}
.serviceList {
  padding: 0;
  margin: 0;
}
.serviceList li:last-child a {
  border-bottom: 0;
  border-radius: 0 0 5px 5px;
}
.serviceList li:first-child a {
  border-radius: 5px 5px 0 0;
}
.testimonialBox {
  background: url(assets/img/quotes.png) no-repeat;
  padding-bottom: 50px;
}
.testimonial_slides .slide___3-Nqo {
  min-height: 240px !important;
  padding: 0 !important;
  outline: none !important;
}
.carousel__inner-slide,
.carousel__inner-slide {
  outline: none !important;
}
.testimonial_slides .slide___3-Nqo h4 {
  font-size: 18px;
  font-weight: normal;
}
:focus {
  outline: none !important;
}
.testimonialBox .MuiTypography-h3 {
  margin-bottom: 10px;
}
.nextButton,
.previousButton {
  top: auto;
  transform: none;
  color: #0112c2;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  bottom: 20px;
  position: absolute;
  text-align: center;
  border: 0;
  line-height: 50px;
  z-index: 9;
  outline: none;
  background: url(assets/img/circle.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
  overflow: hidden;
}
.nextButton {
  background: url(assets/img/circlem.svg) no-repeat;
  background-size: contain;
  background-position: right;
}
.carousel-slider button {
  width: 80px;
  height: 80px;
}
.carousel-slider .nextButton .arrow,
.carousel-slider .previousButton .arrow {
  top: 27px;
}
.carousel-slider .nextButton .arrow {
  right: 24px;
}
.arrow:before {
  content: "";
  width: 2px;
  height: 10px;
  background: #0112c2;
  display: block;
  transform: rotate(45deg);
  position: absolute;
  top: 4px;
}
.arrow:after {
  content: "";
  width: 2px;
  height: 10px;
  background: #0112c2;
  display: block;
  transform: rotate(-45deg);
  top: 10px;
  position: absolute;
}
.carousel-slider button {
  z-index: 999 !important;
}
.arrow.segunda:before,
.arrow.segunda:after {
  left: 0;
}
.nextButton .arrow:before {
  transform: rotate(-45deg);
}
.nextButton .arrow:after {
  transform: rotate(45deg);
}
.previousButton {
  left: 0;
}
.nextButton {
  right: 0;
}
.carousel {
  position: relative;
}
.nextButton .arrow,
.previousButton .arrow {
  position: absolute;
  bottom: 0;
  width: 12px;
  height: 12px;
  background-size: contain;
  top: 12px;
  left: 6px;
  right: 0;
  margin: 0 auto;
}
.nextButton .arrow {
  left: auto;
  right: 10px;
}
.carousel .slider {
  z-index: 99;
  cursor: pointer;
}
@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}
@keyframes bounceAlphareverse {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}
.arrow svg {
  height: auto;
  width: 8px;
  position: absolute;
  top: 50%;
  left: -3px;
  margin: 0 auto;
}
.arrow polygon {
  fill: #0112c2;
}
.arrow {
  animation-name: bounceAlpha;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.nextButton .arrow {
  animation-name: bounceAlphareverse;
}
.nextButton .arrow svg {
  transform: rotate(180deg);
  left: auto;
  right: 0;
}
.arrow.primera {
  animation-delay: 1s;
}
button:disabled {
  opacity: 0.3;
}
button:disabled .arrow {
  animation: none;
}
li.slide > div {
  margin: 0 15%;
}
@media (min-width: 600px) {
  .xs_box5,
  .xs_box6 {
    display: none;
  }
}
@media (max-width: 600px) {
  .MuiContainer-fixed {
    max-width: 100% !important;
  }
}
@media (max-width: 1440px) {
  .pl10 {
    padding-left: 5%;
  }
  .carousel .control-prev.control-arrow {
    left: 20% !important;
  }
  .carousel .control-next.control-arrow {
    right: 14% !important;
  }
}

@media (max-width: 1366px) {
  .MuiDialogContent-root {
    padding: 50px 65px 15px !important;
  }
  .frameworkRow .firstFramework:hover .firstInnerbox:after,
  .frameworkRow .firstFramework:hover .firstInnerbox:before {
    height: 107% !important;
  }
  .pl10 {
    padding-left: 3%;
  }
  header label .overlay {
    right: 24px;
  }
  header label .menu {
    right: 1px;
  }
}
@media (max-width: 1279px) {
  .carousel button {
    bottom: 7px;
  }
  .box2 {
    margin-top: 85px !important;
    margin-bottom: 20px !important;
  }
  .newsletterBox br {
    display: none;
  }
  .firstFramework:before,
  .firstFramework:after,
  .firstInnerbox:before,
  .firstInnerbox:after {
    content: none;
  }
  .frameworkRow .MuiTabs-indicator:after {
    right: -100px;
  }
  .testimonial_slides .MuiList-root {
    margin: 0 auto;
  }
  .carousel.carousel-slider .control-arrow {
    top: 65% !important;
  }
  .testimonial_slides .slide___3-Nqo h4 {
    font-size: 16px;
  }
}
@media (max-width: 959px) {
  .MuiDialogContent-root {
    padding: 65px 25px 15px !important;
  }
  .slide .outerBlog {
    flex-direction: column;
  }
  .testimonial_slides .MuiList-root {
    margin: 50px auto;
  }
  .carousel.carousel-slider .control-arrow {
    display: none;
  }
  li.slide > div {
    margin: 0 15px;
  }
  .testimonialBox {
    padding: 20px 0;
    text-align: center;
    background-position: center 0px;
    background-size: contain;
  }
  .innerBlock .carousel .previousButton {
    left: 15%;
  }
  .innerBlock .carousel .nextButton {
    right: 15%;
  }
  .innerBlock .carousel button {
    bottom: 50px;
  }
  .frameworkRow {
    margin-top: 2rem;
  }
  .box2 {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
  .box3 {
    margin-top: 0 !important;
  }
  .box5 {
    margin-top: 0 !important;
  }
  .box6 {
    margin-top: 0 !important;
  }
  .testimonial_slides .MuiListItemAvatar-root {
    min-width: 70px;
  }
  header label .overlay {
    right: 20px;
  }
  header label .menu {
    right: -3px;
  }
  body {
    font-size: 14px;
    line-height: 1.7;
  }
  .frameworkRow .MuiTab-wrapped {
    padding: 0;
  }
  .frameworkRow span.label {
    font-size: 14px;
  }
  .contactForm .MuiSelect-selectMenu {
    font-size: 16px;
  }
  .location .MuiTypography-body2 {
    font-size: 14px;
  }
  .location .MuiTypography-body1 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 12px;
  }
  .btnMadalClose {
    top: 15px;
    right: 15px;
  }
  .MuiDialogContent-root {
    padding: 40px 20px 10px !important;
  }
  .MuiDialog-paper {
    margin: 5px !important;
    width: 100% !important;
  }
  .testimonial_slides .slide___3-Nqo {
    min-height: 190px !important;
  }
  .testimonial_slides {
    margin-bottom: 0;
  }
  .testimonial_slides .slide___3-Nqo h4 {
    font-size: 14px;
  }
  .testimonial_slides .MuiList-root {
    margin: 25px auto;
  }
  .testimonialBox {
    background-position: center 85%;
    background-size: 60px;
  }
  .frameworkRow span.label {
    font-size: 12px;
  }
  .testimonial_slides .MuiAvatar-root {
    width: 40px;
    height: 40px;
  }
  .testimonial_slides .MuiListItemAvatar-root {
    min-width: 55px;
  }
  .testimonial_slides .MuiTypography-body1 {
    font-size: 13px;
  }
  .testimonial_slides .MuiTypography-colorTextSecondary {
    font-size: 10px;
  }
  .marketing .btn.MuiButton-root {
    min-width: 130px;
  }
  .marketing .btnMargin {
    margin-top: 24px;
  }
  .location .MuiAvatar-colorDefault {
    margin-right: 15px;
  }
  .inqLinks {
    margin-top: 20px !important;
    margin-bottom: 15px;
  }
  .MuiBreadcrumbs-li a {
    font-size: 12px;
  }
}
@media (max-width: 599px) {
  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    min-width: 100% !important;
  }
  .MuiFormControl-root.textField .MuiInputBase-root input {
    font-size: 14px;
    padding: 14px 15px 0;
  }
  .innerBlock .carousel button {
    bottom: 32px;
  }
  .innerBlock .carousel .nextButton {
    right: 0;
  }
  .innerBlock .carousel .previousButton {
    left: 0;
  }
  .MuiFormControl-root.textarea {
    height: 120px;
  }
  .MuiFormControl-root.textField .MuiInputBase-root textarea {
    font-size: 14px;
    padding: 0 0 0 3px;
  }
  .MuiFormControl-root.textField {
    height: 50px;
  }
  .MuiFormControl-root.textField .MuiInputLabel-formControl {
    left: 3px;
    top: -2px;
    font-size: 16px;
  }
  .MuiFormControl-root.textField .MuiFilledInput-multilin {
    padding: 18px 12px 10px;
  }
  .MuiFormControl-root .MuiFilledInput-multiline {
    padding: 22px 12px 7px;
  }
  .startwithus .swipeText {
    margin-top: -55px;
  }
  iframe {
    width: 100%;
    height: 200px;
  }
  .frameworkRow {
    margin-top: 20px;
  }
  .testimonial_slides .MuiList-root {
    display: block;
    margin-top: 20px;
  }
  .frameworkRow .progress {
    width: 40px;
    height: 40px;
  }
  .frameworkRow span.icons {
    width: 40px;
    height: 40px;
    line-height: 47px;
  }
  .frameworkRow span.icons .MuiSvgIcon-root {
    font-size: 1rem;
  }
  .frameworkRow .MuiTab-wrapped:not(:last-child) span.MuiTab-wrapper:after {
    top: 20px;
  }
  .frameworkRow .MuiTabs-indicator:after {
    width: 10px;
    height: 10px;
    top: -22px;
  }
  .frameworkRow .MuiTabs-indicator:after {
    right: -60px;
  }
  .frameworkRow .MuiTab-wrapped {
    min-height: 110px;
  }
  .frameworkRow span.label {
    font-size: 9px;
  }
}
@media (max-width: 360px) {
  .frameworkRow .MuiTabs-indicator:after {
    right: -50px;
  }
}
