.circular_slides {
  position: relative;
  height: 100%;
}
.holderCircle {
  width: 1700px;
  height: 1700px;
  border-radius: 100%;
  margin: 0;
  position: absolute;
  top: -59.5%;
  right: -45%;
  transform: rotate(180deg);
}
.holderCircle:before {
  width: 0;
  height: 0;
  border-right: 500px solid transparent;
  border-top: 500px solid #f7fafc;
  border-left: 500px solid #f7fafc;
  border-bottom: 500px solid #f7fafc;
  border-radius: 100%;
  content: "";
  position: absolute;
  left: 20%;
  top: 20.6%;
  z-index: 99;
}
.holderCircle:after {
  content: none;
}
.dotCircle {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  z-index: 20;
}
.dotCircle:after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translate(0, -50%);
  box-sizing: border-box;
}
.dotCircle::before {
  width: 65%;
  height: 65%;
  content: "";
  position: absolute;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translate(0, -50%);
  box-sizing: border-box;
  z-index: 99;
}
.itemDot {
  display: flex;
  width: 50%;
  height: auto;
  position: absolute;
  background: transparent;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 65px;
  font-size: 30px;
  z-index: 3;
  cursor: pointer;
  z-index: 9;
  transition: 0.5s;
  transition-delay: 0.2s;
  transform: none;
  align-items: center;
  justify-content: space-evenly;
}
.itemDot:before {
  content: "";
  position: absolute;
  width: 95%;
  height: 1px;
  background: #ebebeb;
  transform: rotate(20deg);
  top: -33px;
  left: 91px;
  z-index: 99;
}
.itemDot1:before,
.itemDot3:before {
  top: -33px;
  left: 96px;
}
.itemDot:after {
  content: "";
  position: absolute;
  width: 95%;
  height: 1px;
  background: #ebebeb;
  transform: rotate(-20deg);
  bottom: -30px;
  left: 99px;
  z-index: 9;
}
.forward .itemDot .progress .progress-left {
  transform: rotateX(180deg);
}
.dotCircle .itemDot .progress {
  top: -446px;
  left: 329px;
  width: 1105px;
  height: 1105px;
}
.dotCircle .itemDot2 .progress,
.dotCircle .itemDot4 .progress {
  left: 38%;
}
.dotCircle .itemDot .progress:before {
  /* background-image: linear-gradient(126deg, transparent 50%, #f7fafc 50%),
    linear-gradient(90deg, #f7fafc 50%, transparent 50%); */
  background-image: linear-gradient(90deg, #f7fafc 50%, transparent 50%);
  content: "";
  width: 45%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 9;
}
.dotCircle .progress .progress-left .progress-bar {
  border-top-right-radius: 1105px;
  border-bottom-right-radius: 1105px;
}
.dotCircle .progress .progress-right .progress-bar {
  border-top-left-radius: 1105px;
  border-bottom-left-radius: 1105px;
}
.dotCircle .progress .progress-bar {
  border: 3px solid #0112c2;
}
.dotCircle .itemDot.active .progress .progress-left .progress-bar {
  animation-delay: 0s;
  animation: loading-2 5s linear forwards 1.5s;
}
.itemDot1:after,
.itemDot3:after {
  left: 104px;
}
.itemDot p {
  color: #56617d;
  font-size: 20px;
  font-weight: 500;
  z-index: 99;
}
.itemDot1 {
  transform: rotate(180deg) !important;
  left: 880px !important;
}
.itemDot3 {
  left: -30px !important;
}
.itemDot2 {
  transform: rotate(-90deg) !important;
  top: 1195px !important;
}
.itemDot4 {
  transform: rotate(90deg) !important;
  top: 295px !important;
}
span.number {
  width: 60px;
  height: 60px;
  background: #e5ecfe;
  display: block;
  border-radius: 50%;
  color: #3f5c7e;
  font-weight: 500;
  margin-top: -5px;
}
/* .itemDot {
  display: block;
  width: 65px;
  height: 65px;
  position: absolute;
  background: #ddd;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 65px;
  font-size: 30px;
  z-index: 3;
  cursor: pointer;
  transform: rotate(180deg);
  opacity: 0;
} */
/* .itemDot.itemDot2 {
  transform: rotate(270deg);
}
.itemDot.itemDot3 {
  transform: rotate(0);
}
.itemDot.itemDot4 {
  transform: rotate(90deg);
} */
/* .circular_slides .progress {
  top: -7.5px;
  left: -7.5px;
} */

.img {
  width: 190px;
  border: 10px solid #e5ecfe;
}
.img img {
  max-width: 100%;
}
.circular_slides .progress {
  top: 0;
  left: 0;
}
.circular_slides.carousel-slider button {
  width: 80px;
  height: 80px;
  top: 68%;
  left: auto;
  right: 40%;
  transform: rotate(0deg);
}
.holderCircle button.nextButton {
  bottom: 0;
  top: 27%;
  transform: rotate(180deg);
}
.itemDot.active {
  background: transparent;
  color: #fff;
  transition-delay: 3s;
  transform: none;
}
.dotCircle {
  transform: rotate(360deg);
}
.contentCircle {
  border-radius: 100%;
  position: absolute;
  top: 50%;
  bottom: 0;
  transform: translate(0, -50%);
  right: 0;
  left: 0;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 32px;
}
.contentCircle .CirItem .img {
  border-radius: 100%;
  position: absolute;
  text-align: center;
  left: -125px;
  transform: none;
  width: 250px;
  height: 250px;
  top: 50%;
  border: 1px solid #ebebeb;
}
.contentCircle .CirItem {
  position: absolute;
  opacity: 0;
  transform: translate(0, -50%);
  top: 50%;
}
.contentCircle .CirItem img {
  width: 100%;
  border-radius: 100%;
}
.contentCircle .CirItem.active {
  z-index: 1;
  opacity: 1;
}
.CirItem.active .img {
  transform: none;
}
.content {
  margin-left: 0;
  max-width: 450px;
  opacity: 0;
  transition: all 1s ease;
  transition-delay: 2.5s;
  transform: translateY(-50px);
}
/* .content h3 {
  opacity: 0;
  transition: all 1s ease;
  transition-delay: 2.5s;
  transform: translateY(-5px);
} */
.active .content {
  opacity: 1;
  transform: translateY(0);
}
.backward .active .content {
  transform: translateY(-80px);
}
/* .content h3 + div {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 2s ease;
  transition-delay: 3s;
}
.active .content h3 + div {
  opacity: 1;
  transform: translateY(0);
}
.backward .active .content h3 + div {
  transform: translateY(-20px);
}
.content .read_more {
  opacity: 0;
  transition: opacity, transform 1s ease;
  transition-delay: 4s;
  display: inline-block;
  transform: translateY(-15px);
}
.active .content .read_more {
  opacity: 1;
  transform: translateY(0);
}
.backward .active .content .read_more {
  transform: translateY(-25px);
} */
.btn_nav {
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  position: absolute;
  z-index: 99;
}
.link_prev {
  top: 123px;
  right: 123px;
}
.link_next {
  bottom: 123px;
  right: 123px;
}
span.highlightedDots {
  background: #0112c2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 20%;
  z-index: 9;
}
span.highlightedDots:first-child,
span.highlightedDots:nth-child(2) {
  right: 63px;
}
span.highlightedDots:nth-child(3),
span.highlightedDots:nth-child(4) {
  left: 63px;
}
span.highlightedDots:first-child,
span.highlightedDots:nth-child(4) {
  bottom: 63px;
}
span.highlightedDots:nth-child(2),
span.highlightedDots:nth-child(3) {
  top: 63px;
}
span.highlightedDots:nth-child(5) {
  top: 50%;
  transform: translate(0, -50%);
  left: -8px;
}
span.highlightedDots:nth-child(6) {
  top: 50%;
  transform: translate(0, -50%);
  right: -8px;
}
span.highlightedDots:nth-child(7) {
  top: -8px;
  left: 50%;
  transform: translate(-50%, 0);
}
span.highlightedDots:last-child {
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 0);
}
@media only screen and (min-width: 300px) and (max-width: 599px) {
  .holderCircle {
    width: 300px;
    height: 300px;
    margin: 110px auto;
  }
  .holderCircle::after {
    width: 100%;
    height: 100%;
  }
  .dotCircle {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
@media only screen and (min-width: 600px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
}

@media only screen and (min-width: 1500px) {
}
